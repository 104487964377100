<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('label_filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>

        <!--        <b-col-->
        <!--          cols="12"-->
        <!--          md="3"-->
        <!--          class=" mb-1 date-range-col"-->
        <!--        >-->
        <!--&lt;!&ndash;          <label>{{ $t('label_date_agreement_conclusion') }}</label>&ndash;&gt;-->

        <!--&lt;!&ndash;          <div class="date-range-filter mb-2">&ndash;&gt;-->
        <!--&lt;!&ndash;            <custom-date-picker&ndash;&gt;-->
        <!--&lt;!&ndash;                    class="date&#45;&#45;picker no-label"&ndash;&gt;-->
        <!--&lt;!&ndash;                    :locale="$store.state.appConfig.locale"&ndash;&gt;-->
        <!--&lt;!&ndash;                    :placeholder="$t('label_DD-MM-YYYY')"&ndash;&gt;-->
        <!--&lt;!&ndash;                    :value="dateFromFilter"&ndash;&gt;-->
        <!--&lt;!&ndash;                    @input="(val) => {$emit('update:dateFromFilter', val)}"&ndash;&gt;-->
        <!--&lt;!&ndash;            >&ndash;&gt;-->
        <!--&lt;!&ndash;              <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>&ndash;&gt;-->
        <!--&lt;!&ndash;            </custom-date-picker>&ndash;&gt;-->

        <!--&lt;!&ndash;            <div class="date-separator" />&ndash;&gt;-->
        <!--&lt;!&ndash;            <custom-date-picker&ndash;&gt;-->
        <!--&lt;!&ndash;                    class="date&#45;&#45;picker no-label"&ndash;&gt;-->
        <!--&lt;!&ndash;                    :locale="$store.state.appConfig.locale"&ndash;&gt;-->
        <!--&lt;!&ndash;                    :placeholder="$t('label_DD-MM-YYYY')"&ndash;&gt;-->
        <!--&lt;!&ndash;                    :value="dateToFilter"&ndash;&gt;-->
        <!--&lt;!&ndash;                    @input="(val) => {$emit('update:dateToFilter', val)}"&ndash;&gt;-->
        <!--&lt;!&ndash;            >&ndash;&gt;-->
        <!--&lt;!&ndash;              <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>&ndash;&gt;-->
        <!--&lt;!&ndash;            </custom-date-picker>&ndash;&gt;-->

        <!--&lt;!&ndash;          </div>&ndash;&gt;-->

        <!--          <label>{{ $t('label_procurations_status') }}</label>-->
        <!--          <v-select-->
        <!--                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
        <!--                  :value="procStatusFilter"-->
        <!--                  :options="procStatusOptions"-->
        <!--                  class="w-100"-->
        <!--                  :placeholder="$t('label_select')"-->
        <!--                  :reduce="val => val.value"-->
        <!--                  @input="(val) => $emit('update:procStatusFilter', val)"-->
        <!--          >-->
        <!--            <template v-slot:option="option">-->
        <!--              &lt;!&ndash;<span :class="option.icon"></span>&ndash;&gt;-->
        <!--              <span class="">{{ $t(option.label) }}</span>-->
        <!--            </template>-->
        <!--            <template v-slot:selected-option="option">-->
        <!--              &lt;!&ndash;<span :class="option.icon"></span>&ndash;&gt;-->
        <!--              <span class="">{{ $t(option.label) }}</span>-->
        <!--            </template>-->
        <!--            <template v-slot:no-options="option">-->
        <!--              {{$t('label_no_items')}}-->
        <!--            </template>-->
        <!--          </v-select>-->
        <!--          &lt;!&ndash;</b-form-group>&ndash;&gt;-->
        <!--        </b-col>-->
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >

          <label>{{ $t('label_consultant') }}</label>
          <infinity-scroll
            selected-prop="id"
            url="/consultant"
            :placeholder="$t('label_select')"
            v-model="consultantFilter"
            :default-selection="userData.role == 'consultant' ? userData : false"
            @input="(val) => $emit('update:consultantFilter', val)"
          >
            <template #label="{item}">
              {{ item.name }}
            </template>

          </infinity-scroll>
        </b-col>
        <b-col

          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <div class="mb-2">
            <label>{{ $t('label_deal_status') }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="statusFilter"
              :options="statusOptions"
              class="w-100"
              :placeholder="$t('label_select')"
              :reduce="val => val.id"
              @input="(val) => $emit('update:statusFilter', val)"
            >
              <template v-slot:option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.translation_index) }}</span>
              </template>
              <template v-slot:selected-option="option">
                <!--<span :class="option.icon"></span>-->
                <span class="">{{ $t(option.translation_index) }}</span>
              </template>
              <template v-slot:no-options="option">
                {{ $t('label_no_items') }}
              </template>
            </v-select>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('label_solicitor') }}</label>
          <infinity-scroll

            selected-prop="id"
            url="/solicitor"
            :placeholder="$t('label_select')"
            @input="(val) => $emit('update:solicitorFilter', val)"
          >
            <template #label="{item}">
              {{ item.name }}
            </template>

          </infinity-scroll>

        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('label_recommended') }}</label>

          <!--***********INFINITY**************************************************************************************8-->
          <infinity-scroll
            selected-prop="id"
            url="/agent"
            :placeholder="$t('label_select')"
            @input="(val) => $emit('update:agentFilter', val)"
          >
            <template #label="{item}">
              {{ item.name }}
            </template>

          </infinity-scroll>

        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

    import {
        BCard, BCardHeader, BCardBody, BFormCheckbox,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import  infinityScroll from '@/views/components/infinityScroll'

    export default {
        components: {

            BCard,
            BCardHeader,
            BCardBody,
            BFormCheckbox,

            vSelect,

          infinityScroll

        },
        props: {
            consultantFilter: {
              type: [String, null, Object],
              default: null,
            },
          defaultConsultant: {
            default: null,
          },
            dateFromFilter: {
                type: [String, null],
                default: null,
            },

            dateToFilter: {
                type: [String, null],
                default: null,
            },

            statusOptions: {
                type: Array,
                required: true,
            },
            statusFilter: {
                type: [String, null],
                default: null,
            },
            stageOptions: {
                type: Array,
                required: true,
            },
            stageFilter: {
                type: [String, null],
                default: null,
            },
            procStatusOptions:{
                type: Array,
                required: true,
            },
            procStatusFilter:{
                type: [String, null],
                default: null,
            },

            solicitorFilter: {
                type: [String, null],
                default: null,
            },

            agentFilter: {
                type: [String, null],
                default: null,
            },

        },
        data: () => ({
          userData: JSON.parse(localStorage.getItem('userData')),
        }),
    }
</script>


