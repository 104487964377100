<template>

  <div>

    <div class="breadcrumb justify-content-end">
      <div class="d-flex  breadcrumb-wrapper pr-1">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            class="align-text-top"
            icon="HomeIcon"
            size="15"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          {{ $t('label_deals') }}
        </b-breadcrumb-item>
      </div>
    </div>

    <table-filters
        :consultant-filter.sync="filterData.consultantFilter"
        :default-consultant="null"
      :proc-status-filter.sync="filterData.procStatusFilter"
      :date-from-filter.sync="filterData.dateFromFilter"
      :date-to-filter.sync="filterData.dateToFilter"
      :solicitor-filter.sync="filterData.solicitorFilter"
      :agent-filter.sync="filterData.agentFilter"
      :proc-status-options="proc_statuses"
      :status-filter.sync="filterData.statusFilter"
      :status-options="agreement_statuses"
      :stage-filter.sync="filterData.stageFilter"
      :stage-options="stages"
    />

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

            <v-select
              v-model="tableData.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tableData.perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            >
              <template v-slot:no-options="option">
                {{ $t('label_no_items') }}
              </template>
            </v-select>
            <column-chooser
              v-model="selectedColumns"
              :not-touch="['id']"
              :columns="columnsDefs"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="tableData.searchQuery"
                type="search"
                debounce="500"
                class="d-inline-block mr-1"
                :placeholder="$t('label_search')+'...'"
              />
              <b-button
                v-if="$can('edit', 'deal.tab_details')"
                variant="primary"
                @click="$router.push({name:'deal_add'})"
              >
                <span class="text-nowrap">{{ $t('label_add_deal') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <!--************
            TABLE
            *********************************************-->

      <div class="px-2 table-container-wrap">
        <!--sticky-header-->
        <b-table
          ref="table"
          class="position-relative transited-table"
          :busy="isBusy"
          :items="tableItems"
          responsive
          no-border-collapse
          :fields="selectedColumns"
          primary-key="deal_id"
          :sort-by.sync="tableData.sortBy"
          no-local-sorting
          :sort-desc.sync="tableData.sortDesc"
          show-empty
          :tbody-transition-props="{ name: 'flip-list'}"
          :empty-text="$t('label_no_table_data')"
        >

          <template #head()="data">
            <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
          </template>

          <!--            <template #cell(firm_name)="data">-->
          <!--              <router-link target="_blank" v-if="$can('view', 'contact.tab_details')" :to="{ name: 'contact-details', params: { id: data.item.id } }">-->
          <!--                <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>-->
          <!--                <feather-icon v-else icon="MinusIcon" />-->
          <!--              </router-link>-->
          <!--              <template v-else>-->
          <!--                <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>-->
          <!--                <feather-icon v-else icon="MinusIcon" />-->
          <!--              </template>-->
          <!--            </template>-->

          <!-- Column: Status -->
          <template #cell(counter)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(firm_name)="data">
            <router-link
              v-if="$can('view', 'contact.tab_details') && data.item.contact_id"
              target="_blank"
              :to="{ name: 'contact-details', params: { id: data.item.contact_id } }"
            >
              <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </router-link>
            <template v-else>
              <span v-if="data.item.firm_name">{{ data.item.firm_name }}</span>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>
            <!--                  <div>-->
            <!--                      <template v-if="data.item.contact">-->
            <!--                          <router-link target="_blank" v-if="$can('view', 'contact.tab_details')" :to="{ name: 'contact-details', params: { id: data.item.contact.id } }">-->
            <!--                              {{data.item.contact.first_name }}-->
            <!--                          </router-link>-->
            <!--                          <template v-else >-->
            <!--                              {{data.item.contact.first_name }}-->
            <!--                          </template>-->
            <!--                      </template>-->
            <!--                      <template v-else>&#45;&#45;</template>-->
            <!--                  </div>-->
          </template>
          <template #cell(deal_number)="data">

            <div style="min-width: 110px">
              <router-link
                v-if="$can('view', 'deal.tab_details')"
                target="_blank"
                :to="{ name: 'deal-details', params: { id: data.item.deal.id } }"
              >
                {{ data.item.deal_number }}
              </router-link>
              <template v-else>
                {{ data.item.deal_number }}
              </template>
            </div>
          </template>

          <template #cell(agent)="data">
            <template v-if="data.item.agent">
              <router-link
                v-if="$can('view', 'agent.tab_details')"
                :to="{ name: 'agent-details', params: { id: data.item.agent.id } }"
              >
                {{ data.item.agent.name }}
              </router-link>
              <template v-else>
                {{ data.item.agent.name }}
              </template>
            </template>
            <template v-else>
              --
            </template>
          </template>
          <template #cell(consultant)="data">
            <template v-if="data.item.consultant">
              <router-link
                v-if="$can('view', 'consultant.tab_details')"
                :to="{ name: 'consultant-details', params: { id: data.item.consultant.id } }"
              >
                {{ data.item.consultant.name }}
              </router-link>
              <template v-else>
                {{ data.item.consultant.name }}
              </template>
            </template>
            <template v-else>
              --
            </template>
          </template>

          <template #cell(value_of_claims)="data">
            <b-badge
              v-b-tooltip
              variant="light-primary"
              size="sm"
              :title="data.item.description_of_claims || ''"
            >
              {{ data.item.value_of_claims ? data.item.value_of_claims.formatPrice() : 0 }}
            </b-badge>
          </template>

          <template #cell(deal_status_id)="data">
            <b-badge
              v-if="data.item.deal_status"
              pill
              variant="light-info"
              class="text-capitalize"
            >
              {{ $t(data.item.deal_status.translation_index) }}
            </b-badge>
            <span v-else>--</span>
          </template>
          <template #cell(deal_stage_id)="data">
            <b-badge
              v-if="data.item.deal_stage"
              pill
              variant="light-primary"
              class="text-capitalize"
            >
              {{ $t(data.item.deal_stage.translation_index) }}
            </b-badge>
            <span v-else>--</span>
          </template>

          <template #cell(agreement_status)="data">
            <b-badge
              pill
              :variant="`light-${resolveAgreementStatusVariant(agreement_statuses.findWhere('id', data.item.agreement_status.id)?agreement_statuses.findWhere('id', data.item.agreement_status.id).name_alias : '', true)}`"
              class="text-capitalize"
            >
              {{ data.item.agreement_status ? $t(data.item.agreement_status.translation_index) : '--' }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              dropleft
              boundary="window"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              @show="$event.vueTarget.$refs['toggle'].closest('tr').classList.add('row-selected')"
              @hide="$event.vueTarget.$refs['toggle'].closest('tr').classList.remove('row-selected')"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>


              <!--                      <b-dropdown-item disabled>-->
              <!--                          <span class="badge badge-light-primary d-block">{{ $t('label_change_stage') }}</span>-->
              <!--                      </b-dropdown-item>-->


              <!--                      <b-dropdown-item v-if="data.item.deal_stage_id != 125" @click="changeDealStage(data.item.deal_id,125)">-->
              <!--                          <span class="align-middle ml-50">{{ $t('label_demand_for_payment') }}</span>-->
              <!--                      </b-dropdown-item>-->
              <!--                      <b-dropdown-item v-if="data.item.deal_stage_id != 126" @click="changeDealStage(data.item.deal_id,126)">-->
              <!--                          <span class="align-middle ml-50">{{ $t('label_writ') }}</span>-->
              <!--                      </b-dropdown-item>-->
              <!--                      <b-dropdown-item v-if="data.item.deal_stage_id != 127" @click="changeDealStage(data.item.deal_id,127)">-->
              <!--                          <span class="align-middle ml-50">{{ $t('label_judgment') }}</span>-->
              <!--                      </b-dropdown-item>-->
              <!--                      <b-dropdown-item v-if="data.item.deal_stage_id != 128" @click="changeDealStage(data.item.deal_id,128)">-->
              <!--                          <span class="align-middle ml-50">{{ $t('label_substantiation') }}</span>-->
              <!--                      </b-dropdown-item>-->
              <!--                      <b-dropdown-item v-if="data.item.deal_stage_id != 129" @click="changeDealStage(data.item.deal_id,129)">-->
              <!--                          <span class="align-middle ml-50">{{ $t('label_forward_to_a_lawyer') }}</span>-->
              <!--                      </b-dropdown-item>-->
              <!--                      <b-dropdown-item v-if="data.item.deal_stage_id != 130" @click="changeDealStage(data.item.deal_id,130)">-->
              <!--                          <span class="align-middle ml-50">{{ $t('label_settlement') }}</span>-->
              <!--                      </b-dropdown-item>-->


              <template>
                <b-dropdown-item disabled>
                  <span class="badge badge-light-primary d-block">{{ $t('label_change_status') }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_paid', data.item)">
                  <span class="align-middle ml-50">{{ $t('label_agreement_paid') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="changeContactStatus(data.item.id, 'awaiting_signature', data.item)">
                  <span class="align-middle ml-50">{{ $t('label_awaiting_signature') }}</span>
                </b-dropdown-item>
<!--                <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_signed', data.item)">-->
<!--                  <span class="align-middle ml-50">{{ $t('label_agreement_signed') }}</span>-->
<!--                </b-dropdown-item>-->
                <b-dropdown-item @click="changeContactStatus(data.item.id, 'completing_agreement', data.item)">
                  <span class="align-middle ml-50">{{ $t('label_completing_agreement') }}</span>
                </b-dropdown-item>
                <!-- Modal -->
                <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_paused_status', data.item)">
                  <span class="align-middle ml-50">{{ $t('label_agreement_paused_status') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_finished', data.item)">
                  <span class="align-middle ml-50">{{ $t('label_agreement_finished') }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_conducted', data.item)">
                  <span class="align-middle ml-50">{{ $t('label_agreement_conducted') }}</span>
                </b-dropdown-item>
                <!-- Modal -->
                <b-dropdown-item @click="changeContactStatus(data.item.id, 'agreement_rejected', data.item)">
                  <span class="align-middle ml-50">{{ $t('label_agreement_rejected') }}</span>
                </b-dropdown-item>
              </template>

              <b-dropdown-item disabled>
                <div class="divider">
                  <div class="divider-text">
                    {{ $t('label_action') }}
                  </div>
                </div>
                <!--<span class="badge badge-light-warning d-block"></span>-->
              </b-dropdown-item>
              <b-dropdown-item :to="{name: 'deal_edit', params: { deal_id: data.item.deal_id, id:data.item.agreement_id }}">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('label_edit') }}</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
            </b-col>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="tableData.currentPage"
                :total-rows="tableTotal"
                :per-page="tableData.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </div>

    </b-card>

    <deal-status-modal
      :new-status-id="newDealStatusId"
      :edited-item="editedDeal"
      @item-edited="refreshDataTable()"
    />

  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip,
} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import columnChooser from '@/views/components/columnChooser'
    import dealStatusModal from './modals/processDealStatusModal'

    import tableFilters from './includes/tableFilters.vue'

    import {DEAL_PREFIX as PREFIX, resolveAgreementStatusVariant} from './moduleHelper'

    export default {
        components: {
            BCard,
            BFormInput,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            tableFilters,
            columnChooser,
            dealStatusModal,
            vSelect,
        },
      directives: {
          'b-tooltip': VBTooltip,
      },
        data() {
            return {
              userData: JSON.parse(localStorage.getItem('userData')),
              agreement_statuses:[],
                PREFIX,
                editedItem: false,
                isSidebarOpen: false,
                table_name: 'table-contacts',

                selectedColumns: [],

                columnsDefs: [
                    // {label: '№', key: 'id', sortable: true, thClass: 'hidden', tdClass: 'hidden',},
                  // =  {label: 'label_deal_number', key: 'deal_number', sortable: true},
                  // =  {label: 'label_firm_name', key: 'firm_name', sortable: true},
                  //   {label: 'label_approver', key: 'contact', sortable: true,},
                  //   {label: 'label_date_agreement_conclusion', key: 'created_at', sortable: true, formatter: function (v) {
                  //           return formatDate(v, 'DD.MM.YYYY', 'unix');
                  //       }},
                  // =  {label: 'label_recommended', key: 'agent', sortable: true},
                  // {label: 'label_value_of_claims', key: 'value_of_claims', sortable: true},
                    // = {label: 'label_status', key: 'deal_status_id', sortable: true},
                  // {label: 'label_agreement_status', key: 'agreement_status', sortable: true},
                  // {label: 'label_stage', key: 'deal_stage_id', sortable: true},
                  // =  {label: 'label_action', key: 'actions'},

                    {label: 'label_deal_number', key: 'deal_number', sortable: true},
                  {label: 'label_firm_name', key: 'firm_name', sortable: true},
                  {label: 'label_vehicle_registration_number', key: 'traffic_accident_victim_registration_number', sortable: true},
                  {label: 'label_table_agents', key: 'agent', sortable: true},
                  {label: 'label_pattern_code_type_consultants', key: 'consultant', sortable: true},
                  {label: 'label_status', key: 'agreement_status', sortable: true},
                  {label: 'label_value_of_claims', key: 'value_of_claims', sortable: true},
                  {label: 'label_action', key: 'actions'},

                ],
                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'deal_status_id',
                    sortDesc: false,
                    perPageOptions: [10, 25, 50, 100],
                },
                filterData: {
                    dateFromFilter: null,
                    dateToFilter: null,
                    solicitorFilter: null,
                    agentFilter: null,
                    statusFilter: null,
                    stageFilter: null,
                    procStatusFilter: null,
                    consultantFilter: null,
                    defaultConstultant: null,
                },

                proc_statuses: [],
                statuses: [],
                stages: [],

                newDealStatusId: false,
                editedDeal: false
            }
        },
        computed: {
            dataMeta: (context) => ({
                    from: context.tableData.perPage * (context.tableData.currentPage - 1) + (context.tableItems.length ? 1 : 0),
                    to: context.tableData.perPage * (context.tableData.currentPage - 1) + context.tableItems.length,
                    of: context.tableTotal,
                }),
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    }, 100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        async created() {
          if (this.userData.role === 'consultant') {
            this.filterData.consultantFilter = this.userData.id.toString()
            // const { name, role, id, first_name, last_name } = this.userData
            this.filterData.defaultConstultant = { first_name: '123', last_name: '123', name:' tester' }
            // this.filterData.defaultConstultant = { name, role, id, first_name, last_name }
          }
            await this.getPageData();

            this.columnsDefs = this.columnsDefs.filter((el) => {
                if (el.key == 'actions' && !this.$can('edit', 'deal.tab_details')) {
                    return false;
                }
                return true;
            });

            this.refreshDataTable();
        },

        methods: {
          resolveAgreementStatusVariant,
            getPageData() {
                this.proc_statuses = [{label: 'label_active', value: 'active'}, {
                    label: 'label_inactive',
                    value: 'inactive'
                }];
                this.async('get', '/select_options', {params:{options:['deal_statuses', 'deal_stages', 'agreement_statuses']}}, function(res){
                    // this.taskTypes = res.data.agrm_task_types;
                    this.agreement_statuses = res.data.agreement_statuses;
                    this.statuses = res.data.deal_statuses;
                    this.stages = res.data.deal_stages;
                  Promise.resolve()
                });

            },

            changeDealStage(module_id, stage_id) {
                this.async('put', '/' + this.PREFIX + '/change_stage/' + module_id, {deal_stage_id: stage_id}, function (resp) {
                    this.refreshDataTable();
                });
            },

            changeDealStatus(deal, status_id) {
                this.editedDeal = deal;
                this.newDealStatusId = status_id;
                this.$bvModal.show('confirm-status-modal');

                this.$bvModal.hide
            },

            refreshDataTable: function () {
                this.isBusy = true;
                this.async('get', '/' + this.PREFIX, {
                    params: {
                        length: this.tableData.perPage,
                        start: (this.tableData.currentPage - 1) * this.tableData.perPage,

                        search: this.tableData.searchQuery,
                        sort_by: this.tableData.sortBy,
                        sort_desc: this.tableData.sortDesc? true : null,
                        agreement_at_from: this.filterData.dateFromFilter,
                        agreement_at_to: this.filterData.dateToFilter,
                        procurating_status: this.filterData.procStatusFilter,

                        solicitor_id: this.filterData.solicitorFilter,
                        agent_id: this.filterData.agentFilter,
                        consultant_id: this.filterData.consultantFilter,

                        deal_status_id: this.filterData.statusFilter,
                        deal_stage_id: this.filterData.stageFilter,
                    }
                }, function (resp) {
                    // console.log(resp.data.items)
                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;
                    this.isBusy = false;
                });
            },
            deleteItem(id) {
                this.confirmDeleting((result) => {

                    this.async('delete', '/' + this.PREFIX + '/' + id, {params: {}}, function (resp) {
                        this.refreshDataTable();
                    });
                })
            },
          async changeContactStatus(module_id, status_alias, deal) {
            const status_id = this.agreement_statuses.findWhere('name_alias', status_alias)?.id
            // if (status_id && ['198', '199', '200'].includes(status_id)) {
              this.editedDeal = deal;
              this.newDealStatusId = status_id;
              await this.$bvModal.show('confirm-status-modal');
            // } else {
            //   this.async('put', '/agreement/change_status/' + module_id, {status_alias: status_alias}, function (resp) {
            //     this.refreshDataTable();
            //   });
            // }
          },
            resolveDealStatusVariant(deal_status_id) {
                let alias = this.statuses.findWhere('id', String(deal_status_id)) ? this.statuses.findWhere('id', String(deal_status_id)).name_alias : '--';

                let variant = 'info';
                switch (alias) {
                    case 'judgment':
                        variant = 'danger';
                        break;
                    case 'substantiation':
                        variant = 'info';
                        break;
                    case'demand_for_payment':
                        variant = 'warning';
                        break;
                    case'appeal':
                        variant = 'info';
                        break;
                    case'writ':
                        variant = 'secondary';
                        break;
                    case'settlement':
                        variant = 'dark';
                        break;
                    case'completing_agreement':
                        variant = 'success';
                        break;
                    case'forward_to_lawyer':
                        variant = 'primary';
                        break;

                }

                return variant;
            }

        },

    }
</script>

